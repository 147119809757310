















































import Vue from "vue";
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { AUTH } from "../../routes/endpoints";

export default Vue.extend({
    data() {
        return {
            loading: true,
            isPasswordVisible: false,
            form: {
                email: "",
                password: "",
            },
            AUTH,
        };
    },
    methods: {
        ...mapActions("auth", ["verifyEmail"]),
        goTo({ name }: { name: string }) {
            this.$router.push({ name });
        },
        async verifyUser() {
            try {
                this.loading = true;
                const { token, Email } = this.$route.query;
                const payload = {
                    email: Email,
                    token,
                };

                await this.verifyEmail(payload);

                // if (res.status === 200) {
                //     this.$bvToast.toast(`Email verified successful!`, {
                //         title: "Success!",
                //         variant: "success",
                //         solid: true,
                //     });

                //     const { next } = this.$route.query;
                //     if (next != null && next !== "")
                //         this.$router.replace({ path: next as string });
                //     else this.$router.replace({ name: HOME.INDEX.NAME });
                // }
                this.loading = false;
            } catch (error: unknown) {
                this.loading = false;
            }
        },
    },
    mounted() {
        const { token, Email } = this.$route.query;
        console.log(token, Email);
        if (token && Email) {
            this.verifyUser();
        }
    },
    validations() {
        return {
            form: {
                email: {
                    required,
                    email,
                },
                password: {
                    required,
                },
            },
        };
    },
});
